import {
    AppBar,
    Box,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Menu,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

import {useEffect, useState} from "react";

import Logo from "../img/clockmaker-logo.png"
import LanguagePopup from "./LanguagePopup";
import {useLanguage} from "../context/LanguageContext";


export const Header = ({jwtData}) => {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.data.user)
    const { language, changeLanguage, getT } = useLanguage();


    const [isPwaInstalled, setIsPwaInstalled] = useState(true)
    const [promptStarted, setPromptStarted] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);


    const handleLanguageChange = (value) => {
        changeLanguage(value.toLowerCase())
        // dispatch({ type: 'CHANGE_LANGUAGE', payload: value })
        // window.localStorage.setItem('language', value)
        console.log('===== language updated in interface')
        console.log('===== ' + value.toLowerCase())
        const languageChanged = new CustomEvent('onLanguageChanged', { detail: value });
        window.dispatchEvent(languageChanged);
    };


    useEffect(() => {

        if ('onbeforeinstallprompt' in window) {

            window.addEventListener("beforeinstallprompt", (event) => {
                console.log('event beforeinstallprompt triggered')
                console.log(event)
                event.preventDefault();
                window.installPromptEvent = event;
                window.installPrompt = true
                window.isPwaInstalled = false
                setIsPwaInstalled(false)
                localStorage.setItem("isPwaInstall", "false")

            });
        } else {
            console.log('beforeinstallprompt not supported on this browser.');
        }

    })

    useEffect(() => {
        window.addEventListener("pwaInstallAccepted", function() {
            setIsPwaInstalled(true)
        });

    })

    const installHandler =  () => {
        console.log('pwaWindowOpen');
        if(promptStarted) {
            setDialogOpen(true);
            return false;
        }
        setPromptStarted(true);

        window.postAnalyticsEvent("pwaWindowOpen", window.userID, "", false);

        if (window.installPromptEvent === undefined) {
            console.log("installPromptEvent undefined")
            console.log(window.installPromptEvent)
            return;
        }
        const result = window.installPromptEvent.prompt();
        console.log('result');
        console.log(result);
        window.installPromptEvent.userChoice.then((choiceResult) => {


            if (choiceResult.outcome === 'accepted') {
                console.log('accepted PWA');
                window.isPwaInstalled = true
                localStorage.setItem("isPwaInstall", "true")
                setIsPwaInstalled(true)
                window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=1", false);

            } else {
                localStorage.setItem("isPwaInstall", "false")
                console.log('declineed PWA');

                window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=0", false);

            }

            setPromptStarted(false);

            window.installPromptEvent = null;
        });

    }

    const handleDialogClose = () => {
        setDialogOpen(false);
    };


    useEffect(() => {
        const checkIsPwaInstalled = () => {
            if ('Notification' in window) {

                const currentPermission = Notification.permission;
                const checkPwaInstalled = localStorage.getItem('isPwaInstall');

                if (checkPwaInstalled == 'true' && isPwaInstalled == false) {
                    // Отправляем аналитическое событие
                    window.postAnalyticsEvent("pwaDeleted", window.userID, "", false);
                }
                if(checkPwaInstalled == 'false' && isPwaInstalled) {
                    //window.postAnalyticsEvent("pwaWindowResult", window.userID, "#is#type=1", false);
                }
            }
        };

        // Устанавливаем интервал для регулярной проверки
        const interval = setInterval(checkIsPwaInstalled, 100000); // проверяем каждые 10 секунд

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, []);



    const [languagePopup, setLanguagePopup] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getLoginComponent = () => {


        if(jwtData == null || !jwtData.hasOwnProperty("email")) return <Button className={"login-btn"} onClick={() => {window.showXsollaLogin()}} variant="contained" color="warning" sx={{m:1}}>{getT("login")}</Button>
        else return <>
            <Button
                className={"login-btn"}
                onClick={handleClick}

                variant="contained" color="warning" sx={{m:1}}>{jwtData.hasOwnProperty('name')?jwtData.name:jwtData.email}</Button>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => {
                    localStorage.setItem("jwt","")
                    setTimeout(()=>{document.location.href = '/play/'},500)
                }} >
                    Logout
                </MenuItem>
            </Menu>
        </>
    }

    const languages = [
        { code: 'EN', label: 'English', flagClass: 'fi-en' },
        { code: 'ES', label: 'Español', flagClass: 'fi-es' },
        { code: 'IT', label: 'Italiano', flagClass: 'fi-it' },
        { code: 'PT_BR', label: 'Português do Brasil', flagClass: 'fi-pt_br' },
        { code: 'NL', label: 'Nederlands', flagClass: 'fi-nl' },
        { code: 'DE', label: 'Deutsch', flagClass: 'fi-de' },
        { code: 'FR', label: 'Français', flagClass: 'fi-fr' },
        { code: 'PT', label: 'Português de Portugal', flagClass: 'fi-pt' },
        { code: 'ru', label: 'Русский', flagClass: 'fi-ru' },
        { code: 'KO', label: '한국어', flagClass: 'fi-ko' },
        { code: 'JA', label: '日本語', flagClass: 'fi-ja' },
        { code: 'ZH', label: '简体中文', flagClass: 'fi-zh' },
    ];

    const getLanguageText = () => {
        let l = languages.filter(item => item.code.toLowerCase()==userState.language.toLowerCase())
        if(l.length >= 1) return <span className={`fi ${l[0].flagClass}`} style={{marginRight: '10px'}}></span>
        return <span className={`fi fi-en`} style={{marginRight: '10px'}}></span>
    }

    return <>
        <AppBar position="static">
            <Toolbar>

                <Typography variant="h6" component="div" style={{ flexGrow: 1, display: 'flex' }}>
                    <Box component="img"  src={Logo} sx={{width:'120px'}}></Box>
                </Typography>

                {(!isPwaInstalled&&localStorage.getItem('isPwaInstall')!='true')&&<Button className={"install-btn"} onClick={installHandler} variant="contained" color="warning" sx={{m:1}}>{getT("install")}</Button>}
                {getLoginComponent()}
                <Button className={"language-btn"} variant="contained" color="warning" sx={{m:1}} onClick={() => {setLanguagePopup(true)}}>{getLanguageText()}</Button>

                {/*<Select*/}
                {/*    value={userState.language}*/}
                {/*    onChange={handleLanguageChange}*/}
                {/*    style={{ marginLeft: 'auto' }}*/}
                {/*>*/}
                {/*    <MenuItem value="English">English</MenuItem>*/}
                {/*    <MenuItem value="Spanish">Spanish</MenuItem>*/}
                {/*    <MenuItem value="Chinese">Chinese</MenuItem>*/}
                {/*</Select>*/}

            </Toolbar>
        </AppBar>

        {/*<div>items</div>*/}
        {/*{items?.map((item, index) => (*/}
        {/*    <>*/}
        {/*        <div onClick={() => {*/}
        {/*            db.notificationItems.delete(item.id)*/}
        {/*        }}>id: {item.id}, title: {item.title}, date: {item.date} </div>*/}
        {/*    </>*/}
        {/*))}*/}

        {/*<Box>*/}
        {/*    <TextField placeholder={"Title"} value={title} onChange={handleChangeTitle} />*/}
        {/*    <TextField placeholder={"Message"} value={msg} onChange={handleChange} />*/}
        {/*    <Button onClick={add}>SEND</Button>*/}
        {/*</Box>*/}

        {languagePopup&&<LanguagePopup handleLanguageChange={handleLanguageChange} hidepopup={setLanguagePopup} />}


        <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>{getT("install")}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getT("install_descr")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    </>

}