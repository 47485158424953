import React, {useState, useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import './flags.css';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, TextField,
} from "@mui/material";

import {Header} from "./components/header";
import UnityWrapper from "./unity";

import {
    useCancelNotificationMutation,
    useCheckTokenMutation,
    useSaveTokenMutation,
    useSendMsgMutation
} from "./store/api/api-requests";

import {getMessaging, getToken} from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import {useLiveQuery} from "dexie-react-hooks";
import {initializeApp} from "firebase/app";
import {useDispatch, useSelector} from "react-redux";
import {LanguageProvider, useLanguage} from "./context/LanguageContext";
import translations from "./utils/translations";

import AppStoreImg from "./img/app-store-badge.png"
import AmazonStoreImg from "./img/amazon-store-badge.png"
import GoogleStoreImg from "./img/google-play-badge.png"


const firebaseConfig = {
    apiKey: "AIzaSyA4SwWpW-GwUltuhZQeIYSMk1nsN2wy9Pk",
    authDomain: "cm-test-853b4.firebaseapp.com",
    projectId: "cm-test-853b4",
    storageBucket: "cm-test-853b4.appspot.com",
    messagingSenderId: "1064677070576",
    appId: "1:1064677070576:web:29c6c1ebff53a123769435",
    measurementId: "G-G0TD9K6JK7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = 'Notification' in window ? getMessaging() : null;


function App() {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.data.user)
    const { language, changeLanguage, getT } = useLanguage();


    const [state, setState] = useState({
        language: 'English',
        isCookiePolicyOpen: false,
    });

    const [tempToken, setTempToken] = useState(false)
    const [user, setUser] = useState("")

    const [saveToken, saveTokenResponse] = useSaveTokenMutation()
    const [checkToken, getCheckToken] = useCheckTokenMutation()
    const [sendMsg, sendMsgResponse] = useSendMsgMutation()
    const [cancelNotifications, cancelNotificationsResponse] = useCancelNotificationMutation()

    const [token, setToken] = useState(localStorage.getItem('token')==undefined?'':localStorage.getItem('token'))

    const [openDialog, setOpenDialog] = useState({
        cookie: false,
        notification: false
    });
    const [confirmN, setConfirmN] = useState(false);

    const handleCookiePolicy = (value) => {
        dispatch({ type: 'APPROVE_COOKIE', payload: value })
        setOpenDialog({...openDialog, cookie: false})
        window.localStorage.setItem('cookie_policy', value)
    };


    useEffect(() => {
        const handleCustomUnityMessage = (event) => {
            console.log(event.detail);
        };
        const handleCancelAllScheduledNotifications = (event) => {
            cancelNotifications({
                user_id: window.gameParams.user_id
            })
        };
        const handleUnityChangeLanguage = (event) => {
            console.log('handleUnityChangeLanguage');
            console.log(event.detail);
            changeLanguage(event.detail.value)
        };
        const handleNotifEvent = async (event) => {
            console.log('handleNotifEvent ', event);
            if(Notification.permission == 'granted') {
                sendMsg({
                    title: event.detail.title,
                    body: event.detail.description,
                    date: event.detail.time,
                    event_id: event.detail.eventID,
                    user_id: window.gameParams.user_id,
                    browser: getBrowserName(),
                    timezone: new Date().getTimezoneOffset()
                })
            } else {
                console.log("no permissions")
                console.log(Notification)
                //askNotificationPermission()
            }
        };

        window.addEventListener('sendCustomUnityMessage', handleCustomUnityMessage);
        window.addEventListener('cancelAllScheduledNotifications', handleCancelAllScheduledNotifications);
        window.addEventListener('changeLocalization', handleUnityChangeLanguage);
        window.addEventListener('requestNotificationPermission', showNotificationPermissionDialog);//askNotificationPermission
        window.addEventListener('notifEvent', handleNotifEvent);

        return () => {
            window.removeEventListener('sendCustomUnityMessage', handleCustomUnityMessage);
            window.removeEventListener('cancelAllScheduledNotifications', handleCancelAllScheduledNotifications);
            window.removeEventListener('changeLocalization', handleUnityChangeLanguage);
            window.removeEventListener('requestNotificationPermission', showNotificationPermissionDialog);
            window.removeEventListener('notifEvent', handleNotifEvent);

        };
    }, []);



    window.isTokenFetching = false;

    const isMobile = ((navigator.userAgent.match("Mac") || navigator.userAgent.match("Linux")) &&
        navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && navigator.maxTouchPoints !== 256)

    const getBrowserName = () => {
        const userAgent = navigator.userAgent;

        if (userAgent.includes("Firefox")) {
            return "Firefox";
        } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
            return "Opera";
        } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Chrome";
        } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
            return "Safari";
        } else if (userAgent.includes("Edg")) {
            return "Edge";
        } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
            return "Internet Explorer";
        } else {
            return "unknown";
        }
    }

    const requestForToken = async () => {
        debugger
        if (window.isTokenFetching) return false;
        window.isTokenFetching = true

        // const storedToken = localStorage.getItem('token');
        // if (storedToken) {
        //     setToken(storedToken);
        //     return storedToken;
        // }

        try {
            const currentToken = await getToken(messaging, { vapidKey: 'BLX3LM5yo1SXgSuVa3BDruQlfqXTLQSENxDD4HVzzLsZDeGmK_6DwjP2nVLpR5EskX8z2i3v7NnaQaDhe16nYiQ' })
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                setToken(currentToken)
                setTempToken(currentToken)
                saveToken({
                    token:currentToken,
                    user_id: window.gameParams.user_id,
                    browser: getBrowserName()
                })
                localStorage.setItem('token',currentToken)
                window.isTokenFetching = false
                return currentToken
            } else {
                console.log('No registration token available. Request permission to generate one.');
                return false;
            }
        } catch (err) {
            console.log('An error occurred while retrieving token. ', err);
            throw err; // Rethrow the error if needed
        }
    };


    const checkNotificationPromise = () => {
        let result = false;
        try {
            console.log("ok perm prom")
            Notification.requestPermission().then((f) => {
                console.log('f: '+f)

                result = true;
            }).catch((e) => {
                console.log('e: ' +e)
                result = false
            });
        } catch(e) {
            console.log("nook perm prom")
            result = false
            return result;
        }

        return result;
    }

    const handlePermissionPromise = (permission) => {

        if (permission === 'granted') {
            console.log('start token request')
            window.postAnalyticsEvent("notificationEnable", window.userID, "", false);

            requestForToken().then()
        } else {
            window.postAnalyticsEvent("notificationDisable", window.userID, "", false);
        }

    }
    useEffect(() => {
        const checkNotificationPermission = () => {
            try {
                console.log('checkNotificationPermission')

                const currentPermission = Notification.permission;
                const lastPermission = localStorage.getItem('lastNotificationPermission');

                console.log(currentPermission)
                console.log(lastPermission)

                // Сохраняем текущее разрешение как последнее проверенное
                localStorage.setItem('lastNotificationPermission', currentPermission);

                // Если разрешение изменилось с 'granted' на 'denied'
                if ((lastPermission === 'granted' || lastPermission == 'default') && (currentPermission === 'denied' || currentPermission === 'default')) {

                    // Отправляем аналитическое событие
                    window.postAnalyticsEvent("notificationDisable", window.userID, "", false);
                }
                if((lastPermission == 'denied' && currentPermission == 'granted') || (lastPermission == 'default' && currentPermission == 'granted') ) {
                    console.log('start token request 2')
                    window.postAnalyticsEvent("notificationEnable", window.userID, "", false);

                    requestForToken().then()
                }
            } catch (e) {

            }

        };

        // Устанавливаем интервал для регулярной проверки
        const interval = setInterval(checkNotificationPermission, 5000); // проверяем каждые 10 секунд

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, []);


    const handlePermission = (permission) => {
        if (!('permission' in Notification)) {
            Notification.permission = permission;
        }
    }

    const showNotificationPermissionDialog = () => {
        if(Notification.permission == 'default') askNotificationPermission()
        else  setOpenDialog({...openDialog, notification: true}); // Open dialog if permission is default
    }

    const askNotificationPermission = () => {
        localStorage.setItem('lastNotificationPermission', Notification.permission);

        if(Notification.permission == 'granted') return false;

        if (!("Notification" in window)) {
            console.error("This browser does not support notifications.");
        } else {
            console.log("start request N")
            if (Promise) {
                Notification.requestPermission().then((permission) => {
                    console.log(('request perm PROMISE'))

                    handlePermissionPromise(permission)
                }).catch((e) => {
                    console.log(('request perm PROMISE err'))

                });
            } else {
                Notification.requestPermission(function(permission) {
                    console.log(('request perm'))
                    handlePermissionPromise(permission)
                });
            }
        }
    }

    const requestPermissionsPopup = () => {
        if (!("Notification" in window)) {
            console.error("This browser does not support notifications.");
            return;
        }

        // Check current permission status
        const permission = Notification.permission;
        if (permission === "default") {
            // Permission not granted or denied yet, show dialog to ask for permission
            setOpenDialog({...openDialog, notification: true});
        }

    }

    useEffect(() => {
        console.log("use eff confirm N")
        console.log(confirmN)
        if(confirmN == true) {
            //askNotificationPermission()
            //requestPermissionsPopup()
        }
    }, [confirmN])



    useEffect(() => {
        let userId = window.initApp()
        setUser(userId)
    },[])

    const sendNotification = () => {
        if (Notification.permission === "granted") {
            navigator.serviceWorker.getRegistration().then(function(reg) {
                reg.showNotification('Hello')
            }).catch((e) => {

            });
        }
    }


    const [play, setPlay] = useState(false)

    const urlParams = new URLSearchParams(window.location.search);
    const initialBuild = urlParams.get('build') || localStorage.getItem('build') || '5375';
    const initialCheats = urlParams.get('cheats') || localStorage.getItem('cheats') || '0'; // Defaults to '0' if nothing is set

    const [iB, setiB] = useState(initialBuild);
    const [iCheat, setiCheat] = useState(initialCheats);

    const handleBuildChange = (event) => {
        const newBuild = event.target.value;
        localStorage.setItem('build', newBuild);  // Update local storage
        setiB(newBuild);  // Update state
    };

    const handleCheatChange = (event) => {
        const newCheat = event.target.value;
        localStorage.setItem('cheats', newCheat);  // Update local storage
        setiCheat(newCheat);  // Update state
    };

    const jwtData = window.getParsedXsollaJwt()
    console.log('===jwtData===')
    console.log(jwtData)
    console.log(user)

    return (
        <>

            <div className={`App ${isMobile ? 'mobile-bg' : 'cm-bg'}`}>
                <Header jwtData={jwtData} />


                {!isMobile ? <>
                    {(play||(user!=false&&user!=undefined)) ? <UnityWrapper />: <div className={"startPlay"}>
                        <div className="playBtn-wrapper">
                            <div className={"playBtn"} onClick={() => {
                                const jwtData = window.getParsedXsollaJwt()
                                if(jwtData == null || !jwtData.hasOwnProperty("email")) {
                                    window.showXsollaLogin()
                                } else {
                                    setPlay(true)}
                            }

                            }></div>
                        </div>
                    </div>}
                </>:<div id="blocker-wrapper"><div id="blocker">
                    <div id="blocker-label">
                        <div>
                            {getT('game_start_error')}

                        </div>
                    </div>
                    <div id="store-links-container">
                        <a href="https://apps.apple.com/us/app/clockmaker-match3-puzzle/id929195587" className="store-link">
                            <img src={AppStoreImg}
                                 className="store-link-image" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.belkatechnologies.clockmaker&amp;hl=en"
                           className="store-link">
                            <img src={GoogleStoreImg}
                                 className="store-link-image" />
                        </a>
                        <a href="https://www.amazon.com/Samfinaco-Limited-Clockmaker-Amazing-Match/dp/B00YXZ4L2Q/ref=sr_1_1?dchild=1&amp;keywords=clockmaker&amp;qid=1631532787&amp;sr=8-1"
                           className="store-link">
                            <img src={AmazonStoreImg}
                                 className="store-link-image" />
                        </a>
                    </div>
                </div></div>
                }
            </div>



            <footer style={{ }}>
                <div>
                    © Belka Games, 2024<br />
                    Samfinaco LLC<br />
                    1100 Page MillRoad Palo Alto, CA 94304
                </div>
                <div>
                    <Button href={"https://support.belka-games.com/hc/en-us/categories/360002081597-Clockmaker"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("support")}</Button>
                    <Button href={"https://belka-games.com/"+language.toLowerCase()+"/privacy"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("privacy")}</Button>
                    <Button href={"https://belka-games.com/"+language.toLowerCase()+"/terms-of-service"} target={"_blank"} variant="contained" color="warning" sx={{m:1}}>{getT("terms")}</Button>

                </div>
            </footer>
            <Dialog
                open={openDialog.notification}
                onClose={() => setOpenDialog({...openDialog, notification: false})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {getT("show_notification_title")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div dangerouslySetInnerHTML={{__html:getT("show_notification_description")}}></div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog({...openDialog, notification: false})}>{getT("no_close")}</Button>
                    <Button  target="_blank" href={getT('show_instructions_link')} onClick={() => { setOpenDialog({...openDialog, notification: false}); }} autoFocus>
                        {getT("show_instructions")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default App;
