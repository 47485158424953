import React, {useEffect, useRef, useState} from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress
} from "@mui/material";
import {useLanguage} from "./context/LanguageContext";


const UnityWrapper = () => {

    const { language, changeLanguage, getT } = useLanguage();

    const urlParams = new URLSearchParams(window.location.search);

    function getStorageItem(key, defaultValue) {
        const storedValue = localStorage.getItem(key);
        return (storedValue !== null && storedValue !== 'null' && storedValue !== undefined) ? storedValue : defaultValue;
    }

    const initialBuild = urlParams.get('build') || window.unityBuildNumber;
    const initialType = urlParams.get('type') || 'dev';


    localStorage.setItem('build', initialBuild)
    localStorage.setItem('type', initialType)

    const buildType = 'downscale'

    const urls = {
        'prod': 'https://mcmustatic.b-cdn.net/mcmu-standalone-prod/Build-',
        'dev':'https://cm-staging-webgl.belka-games.com/static/mcmu-standalone-dev/Build-',
        'prod_cheats': 'https://mcmustatic.b-cdn.net/mcmu-standalone-prod-cheats/Build-',
    }

    let url = urls.hasOwnProperty(initialType) ? urls[initialType] : urls['dev']
    url += initialBuild + "/" + buildType


    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, UNSAFE__unityInstance } = useUnityContext({
        dataUrl: url + "/Build/"+buildType+".data",
        frameworkUrl: url + "/Build/"+buildType+".framework.js",
        codeUrl: url + "/Build/"+buildType+".wasm",
        loaderUrl: url + "/Build/"+buildType+".loader.js",
        streamingAssetsUrl: url + "/StreamingAssets",
        companyName: "BelkaGames",
        productName: "CM",
        productVersion: "1.0",
    })

    useEffect(() => {
            window.unityInstance = UNSAFE__unityInstance
        },
        [UNSAFE__unityInstance]
    );

    const prevLanguageRef = useRef(language);

    useEffect(() => {
        if (isLoaded && language != undefined && language != '' && language !== prevLanguageRef.current) {
            changeUnityLanguage(language.toUpperCase());
            prevLanguageRef.current = language;
        }
    }, [language, isLoaded]);


    const changeUnityLanguage = (language) => {
        console.log('===== changeUnityLanguage (request to unity)')
        console.log('===== ' + language.toLowerCase())
        sendMessage("PWAJSHelper", "OnLocalizationChanged",language.toLowerCase());
        //window.unityInstance.SendMessage("PWAJSHelper", "OnLocalizationChanged",language.toUpperCase());
    }

    const handleEvent = (event) => {
        console.log("unity event")
        console.log(event)
    }

    useEffect(() => {
        addEventListener("CancelAllScheduledNotifications", handleEvent);
        addEventListener("RequestNotificationPermission", handleEvent);
        addEventListener("IsNotificationPermissionGranted", handleEvent);
        addEventListener("ChangeLocalization", handleEvent);
        addEventListener("GetLocalization", handleEvent);
        addEventListener("PWAInteropGetLocalization", handleEvent);
        addEventListener("PWAInteropScheduleNotification", handleEvent);
        addEventListener("ScheduleNotification", handleEvent);

    });

    //
    // useEffect(() => {
    //     const handleLanguageChanged = (event) => {
    //         changeUnityLanguage(event.detail.toUpperCase())
    //     }
    //     window.addEventListener('onLanguageChanged', handleLanguageChanged);//askNotificationPermission
    //     return () => {
    //         window.removeEventListener('onLanguageChanged', handleLanguageChanged);
    //     };
    // }, []);

    return (
        <>
            {!isLoaded && (
                <div className="gameWrapper">
                    <div className={"startPlay"}>
                        <div className={"loading"}>
                            {getT('loading')} {Math.round(loadingProgression * 100)}%
                            <LinearProgress sx={{pt:1,mt:1}} variant="determinate" thickness={4} value={loadingProgression * 100} />
                        </div>
                    </div>
                </div>
            )}

            <Unity
                matchWebGLToCanvasSize={true}
                unityProvider={unityProvider}
                style={{ visibility: isLoaded ? "visible" : "hidden" }}
            />


        </>
    );

};

export default UnityWrapper;